import styles from "./ElectronicPaymentFooter.module.css";

function ElectronicPaymentFooter() {
  return (
    <footer className={styles["app-footer"]}>
      <span>CONTACTO</span>
      <span className={styles.line} />
      <div className={styles["vertical-space"]}></div>
    </footer>
  );
}

export default ElectronicPaymentFooter;
