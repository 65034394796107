import { useState, useEffect, forwardRef } from "react";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  ListItemText,
  ListItem,
  List,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Modal,
  Box,
} from "@mui/material";
import { Settings, Logout, Close } from "@mui/icons-material";
import { Breakpoint } from "react-socks";

import styles from "./SoaHeader.module.css";
import { USER_TOKEN } from "../../../utils/constants";
import { validateToken } from "../../login/validateToken";
import bseLogo from "../../../images/soa-bse-logo.png";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SoaHeader() {
  const [fullName, setFullName] = useState("");
  const [nameInitials, setNameInitials] = useState("");
  const [documentTypeLabel, setDocumentTypeLabel] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [email, setEmail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);

  useEffect(() => {
    const userPayload = validateToken();
    if (userPayload.isLoggedIn) {
      setFullName(userPayload.publicProfile.fullName);
      setNameInitials(userPayload.publicProfile.nameInitials);
      setDocumentTypeLabel(userPayload.documentTypeLabel);
      setDocumentId(userPayload.documentId);
      setEmail(userPayload.publicProfile.email);
    } else {
      cleanUser();
    }
  }, []);

  const cleanUser = () => {
    setFullName("");
    setNameInitials("");
    setDocumentTypeLabel("");
    setDocumentId("");
    setEmail("");
  };

  const openMenu = Boolean(anchorEl);
  const handleClickIconButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    setOpenProfile(true);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const handleOpenConfig = () => setOpenConfig(true);

  const handleCloseConfig = () => setOpenConfig(false);

  const handleClickLogout = () => {
    handleCloseMenu();
    sessionStorage.removeItem(USER_TOKEN);
    cleanUser();
  };

  const upperHeader = (
    <>
      {fullName && (
        <div className={styles["username-container"]}>
          <Tooltip title="Configuración de la cuenta">
            <IconButton
              onClick={handleClickIconButton}
              size="small"
              sx={{ ml: 2 }}
            >
              <Avatar sx={{ bgcolor: "#e29000" }}>{nameInitials}</Avatar>
            </IconButton>
          </Tooltip>
          <span className={styles.username}>{fullName}</span>
        </div>
      )}
    </>
  );

  return (
    <>
      <header className={styles["app-header"]}>
        <Breakpoint medium down>
          <div className={styles["header-container-responsive"]}>
            <img
              src={bseLogo}
              alt="logo BSE"
              className={styles["logo-responsive"]}
            />
            {upperHeader}
          </div>
        </Breakpoint>

        <Breakpoint large up>
          <div className={styles["header-container"]}>
            <img src={bseLogo} alt="logo BSE" className={styles.logo} />
            {upperHeader}
          </div>
        </Breakpoint>

        <div className={styles["title-container"]}>
          <span className={styles["main-title"]}>
            Cotizá tu seguro <strong>SOA</strong>
          </span>
          <span className={styles.line} />
        </div>
      </header>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleOpenProfile}>
          <Avatar sx={{ bgcolor: "#e29000" }} />
          <span className={styles.menuItemText}>Mi Perfil</span>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenConfig}>
          <ListItemIcon sx={{ color: "#e29000" }}>
            <Settings fontSize="small" />
          </ListItemIcon>
          <span className={styles.menuItemText}>Configuración</span>
        </MenuItem>
        <MenuItem onClick={handleClickLogout}>
          <ListItemIcon sx={{ color: "#e29000" }}>
            <Logout fontSize="small" />
          </ListItemIcon>
          <span className={styles.menuItemText}>Salir</span>
        </MenuItem>
      </Menu>
      <Dialog
        fullScreen
        open={openProfile}
        onClose={handleCloseProfile}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", background: "#00A1DF" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseProfile}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Mi Perfil
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseProfile}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Nombre completo" secondary={fullName} />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Iniciales" secondary={nameInitials} />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Tipo de documento"
              secondary={documentTypeLabel}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Número de documento"
              secondary={documentId}
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Correo electrónico" secondary={email} />
          </ListItem>
          <Divider />
        </List>
      </Dialog>
      <Modal
        open={openConfig}
        onClose={handleCloseConfig}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Configuración
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default SoaHeader;
