import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Breakpoint } from "react-socks";

import styles from "./SoaElectronicPaymentDetailStepOne.module.css";

function SoaElectronicPaymentDetailStepOne() {
  const vehicleTypeLabel = useSelector((state) => state.vehicleTypeLabel);
  const brandLabel = useSelector((state) => state.brandLabel);
  const year = useSelector((state) => state.year);
  const plate = useSelector((state) => state.plate);
  const chassis = useSelector((state) => state.chassis);
  const census = useSelector((state) => state.census);
  const engine = useSelector((state) => state.engine);
  const noData = "―";

  const chunkString = (str, length) => {
    const chunks =
      str && str !== null
        ? str.match(new RegExp(".{1," + length + "}", "g"))
        : [];

    return chunks ? (
      chunks.map((text, index) => (
        <Fragment key={index}>
          {text}
          <br />
        </Fragment>
      ))
    ) : (
      <></>
    );
  };

  return (
    <>
      <Breakpoint medium down>
        <section className={styles["container-responsive"]}>
          <div className={`${styles.text} ${styles.title}`}>
            Tipo de vehículo:
          </div>
          <div className={styles.text}>{vehicleTypeLabel}</div>
          <div className={`${styles.text} ${styles.title}`}>Año:</div>
          <div className={styles.text}>{year}</div>
          <div className={`${styles.text} ${styles.title}`}>Marca:</div>
          <div className={styles.text}>{brandLabel}</div>
          <div className={`${styles.text} ${styles.title}`}>Matrícula:</div>
          <div className={styles.text}>{plate ? plate : noData}</div>
          <div className={`${styles.text} ${styles.title}`}>Chasis:</div>
          <div className={styles.text}>
            {chassis ? chunkString(chassis, 10) : noData}
          </div>
          <div className={`${styles.text} ${styles.title}`}>Padrón:</div>
          <div className={styles.text}>{census ? census : noData}</div>
          <div className={`${styles.text} ${styles.title}`}>Motor:</div>
          <div className={styles.text}>
            {engine ? chunkString(engine, 10) : noData}
          </div>
        </section>
      </Breakpoint>

      <Breakpoint large up>
        <section className={styles.container}>
          <div className={`${styles.text} ${styles.title}`}>
            Tipo de vehículo:
          </div>
          <div className={`${styles.text} ${styles.title}`}>Año:</div>
          <div className={`${styles.text} ${styles.title}`}>Marca:</div>
          <div className={styles.text}>{vehicleTypeLabel}</div>
          <div className={styles.text}>{year}</div>
          <div className={styles.text}>{brandLabel}</div>
          <div className={`${styles.text} ${styles.title}`}>Matrícula:</div>
          <div className={`${styles.text} ${styles.title}`}>Chasis:</div>
          <div className={`${styles.text} ${styles.title}`}>Padrón:</div>
          <div className={styles.text}>{plate ? plate : noData}</div>
          <div className={styles.text}>
            {chassis ? chunkString(chassis, 10) : noData}
          </div>
          <div className={styles.text}>{census ? census : noData}</div>
          <div className={`${styles.text} ${styles.title}`}>Motor:</div>
          <div className={styles.text} style={{ gridRow: 6, gridColumn: 1 }}>
            {engine ? chunkString(engine, 10) : noData}
          </div>
        </section>
      </Breakpoint>
    </>
  );
}

export default SoaElectronicPaymentDetailStepOne;
