import {
  SOA_QUOTE_RESET_TYPE,
  SOA_QUOTE_UPDATE_STEP_TYPE,
  SOA_QUOTE_STEP_ONE_SUCCESS_TYPE,
  SOA_QUOTE_STEP_TWO_SUCCESS_TYPE,
  SOA_QUOTE_STEP_THREE_SUCCESS_TYPE,
  SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE,
  ISSUE_INVOICE_REQUEST,
  ISSUE_INVOICE_SUCCESS,
  ISSUE_INVOICE_FAILURE,
  SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE,
  SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE,
} from "./types.js";
import { INITIAL_STATE } from "./initialState";
import {
  SOA_QUOTE_STEP_FOUR,
  SOA_QUOTE_STEP_THREE,
  SOA_QUOTE_STEP_TWO,
} from "../components/soaQuote/utils/constants.js";
import {
  ELECTRONIC_PAYMENT_INIT,
  ELECTRONIC_PAYMENT_STEP_ONE,
  ELECTRONIC_PAYMENT_STEP_TWO,
  ELECTRONIC_PAYMENT_STEP_THREE,
} from "../components/electronicPayment/utils/constants.js";

const soaReducer = (state = {}, action) => {
  switch (action.type) {
    case SOA_QUOTE_RESET_TYPE: {
      // Must always return a new state
      return { ...INITIAL_STATE };
    }
    case SOA_QUOTE_UPDATE_STEP_TYPE: {
      // Must always return a new state
      return {
        ...state,
        soaQuoteStep: action.payload,
        paymentStep: ELECTRONIC_PAYMENT_INIT,
      };
    }
    case SOA_QUOTE_STEP_ONE_SUCCESS_TYPE: {
      // Must always return a new state
      return {
        ...state,
        soaQuoteStep: SOA_QUOTE_STEP_TWO,
        paymentStep: ELECTRONIC_PAYMENT_INIT,
        ...action.payload,
      };
    }
    case SOA_QUOTE_STEP_TWO_SUCCESS_TYPE: {
      // Must always return a new state
      return {
        ...state,
        soaQuoteStep: SOA_QUOTE_STEP_THREE,
        paymentStep: ELECTRONIC_PAYMENT_INIT,
        ...action.payload,
      };
    }
    case SOA_QUOTE_STEP_THREE_SUCCESS_TYPE: {
      // Must always return a new state
      return {
        ...state,
        soaQuoteStep: SOA_QUOTE_STEP_FOUR,
        paymentStep: ELECTRONIC_PAYMENT_STEP_ONE,
        ...action.payload,
      };
    }
    case SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE: {
      // Must always return a new state
      return { ...state, paymentStep: action.payload };
    }
    case ISSUE_INVOICE_REQUEST: {
      // Must always return a new state
      return {
        ...state,
        issueInvoiceIsLoading: true,
        issueInvoiceIsError: false,
      };
    }
    case ISSUE_INVOICE_SUCCESS: {
      // Must always return a new state
      return {
        ...state,
        ...action.payload,
        issueInvoiceIsLoading: false,
        issueInvoiceIsError: false,
      };
    }
    case ISSUE_INVOICE_FAILURE: {
      // Must always return a new state
      return {
        ...state,
        issueInvoiceIsLoading: false,
        issueInvoiceIsError: true,
      };
    }
    case SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE: {
      // Must always return a new state
      return {
        ...state,
        paymentStep: ELECTRONIC_PAYMENT_STEP_TWO,
      };
    }

    case SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE: {
      // Must always return a new state
      return {
        ...state,
        paymentStep: ELECTRONIC_PAYMENT_STEP_THREE,
      };
    }

    default:
      return state;
  }
};

export default soaReducer;
