import { applyMiddleware, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"; // sessionStorage (web)

import { INITIAL_STATE } from "./initialState";
import rootReducer from "./reducers";

const persistConfig = {
  key: "SOARoot",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const devtools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

function configureStore() {
  let store =
    process.env.REACT_APP_INCLUDE_DEVTOOLS === "yes"
      ? createStore(
          persistedReducer,
          INITIAL_STATE,
          compose(applyMiddleware(reduxThunk), devtools)
        )
      : createStore(
          persistedReducer,
          INITIAL_STATE,
          compose(applyMiddleware(reduxThunk))
        );

  let persistor = persistStore(store);
  return { store, persistor };
}

export default configureStore;
