import { useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Alert, Container } from "@mui/material";

import styles from "./SoaQuoteFormStepThree.module.css";
import { soaQuoteStepThreeSuccess } from "../../../redux/actions";
import { ROUTE_ELECTRONIC_PAYMENT_STEP_ONE } from "../../electronicPayment/utils/routes";

function SoaQuoteFormStepThree() {
  const [error, setError] = useState(false);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const history = useHistory();

  const onSubmit = (data) => {
    if (data.plate === "" && data.chassis === "") {
      setError(true);
      setFocus("plate");
    } else {
      setError(false);

      dispatch(soaQuoteStepThreeSuccess(data)); // Add new data to the store
      history.push(ROUTE_ELECTRONIC_PAYMENT_STEP_ONE);
    }
  };

  return (
    <Container fixed>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles["title-container"]}>
          <span className={styles.title}>Identificación del vehículo</span>
        </div>
        <p />
        <label className={styles.label} htmlFor="plate">
          Matrícula:
        </label>
        <input
          type="text"
          autoFocus
          placeholder="Ingrese el número de matrícula"
          className={styles.input}
          {...register("plate", { maxLength: 10, pattern: /^[a-z0-9]+$/i })}
        />
        <span className={styles.note}>
          Debe ingresar obligatoriamente el número de matrícula o del chasis
        </span>

        {errors.plate?.type === "maxLength" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            La 'Matrícula' es demasiado larga
          </Alert>
        )}

        {errors.plate?.type === "pattern" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            La 'Matrícula' solo puede incluir letras y números sin espacios
          </Alert>
        )}

        {error && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            Debe ingresar un número de matrícula o un número de chasis
          </Alert>
        )}

        <label className={styles.label} htmlFor="chassis">
          Chasis:
        </label>
        <input
          type="text"
          placeholder="Ingrese el número de chasis"
          className={styles.input}
          {...register("chassis", { maxLength: 30, pattern: /^[a-z0-9]+$/i })}
        />

        {errors.chassis?.type === "maxLength" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El 'Chasis' es demasiado largo
          </Alert>
        )}

        {errors.chassis?.type === "pattern" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El 'Chasis' solo puede incluir letras y números sin espacios
          </Alert>
        )}

        <label className={styles.label} htmlFor="census">
          Padrón:
        </label>
        <input
          type="text"
          placeholder="Ingrese el número de padrón"
          className={styles.input}
          {...register("census", { maxLength: 10, pattern: /^[a-z0-9]+$/i })}
        />

        {errors.census?.type === "maxLength" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El 'Padrón' es demasiado largo
          </Alert>
        )}

        {errors.census?.type === "pattern" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El 'Padrón' solo puede incluir letras y números sin espacios
          </Alert>
        )}

        <label className={styles.label} htmlFor="engine">
          Motor:
        </label>
        <input
          type="text"
          placeholder="Ingrese el número de motor"
          className={styles.input}
          {...register("engine", { maxLength: 22 })}
        />

        {errors.engine?.type === "maxLength" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El 'Motor' es demasiado largo
          </Alert>
        )}

        <input className={styles.mainButton} type="submit" value="Contratar" />
      </form>
    </Container>
  );
}

export default SoaQuoteFormStepThree;
