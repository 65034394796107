import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BreakpointProvider } from "react-socks";

import "./App.css";
import configureStore from "./redux/configureStore";
import SoaQuote from "./components/soaQuote/SoaQuote";
import Loading from "./components/Loading";

const { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading size="big" />} persistor={persistor}>
        <BreakpointProvider>
          <div className="background">
            <SoaQuote />
          </div>
        </BreakpointProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
