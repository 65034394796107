import styles from "./SoaElectronicPaymentSuccessStepThree.module.css";
import circleTick from "../../../../images/circle-tick.png";
import { getEMail } from "../../../login/validateToken";
import { Breakpoint } from "react-socks";

function SoaElectronicPaymentSuccessStepThree() {
  return (
    <>
      <Breakpoint medium down>
        <section className={styles.container}>
          <img
            src={circleTick}
            alt="Listo"
            className={styles["image-responsive"]}
          />
          <div className={styles.title}>Transacción confirmada con éxito</div>
          <div className={styles.text}>
            Recibirás la documentación correspondiente en tu correo electrónico{" "}
            {getEMail()}
          </div>
        </section>
      </Breakpoint>

      <Breakpoint large up>
        <section className={styles.container}>
          <div className={styles["inner-container"]}>
            <img src={circleTick} alt="Listo" className={styles.image} />
            <div className={styles.title}>Transacción confirmada con éxito</div>
          </div>
          <div className={styles.text}>
            Recibirás la documentación correspondiente en tu correo electrónico{" "}
            {getEMail()}
          </div>
        </section>
      </Breakpoint>
    </>
  );
}

export default SoaElectronicPaymentSuccessStepThree;
