import axios from "axios";

import {
  API_ISSUE_URL,
  API_INVOICE_URL,
} from "../components/electronicPayment/utils/urls";
import { validateToken } from "../components/login/validateToken";
import {
  SOA_QUOTE_RESET_TYPE,
  SOA_QUOTE_UPDATE_STEP_TYPE,
  SOA_QUOTE_STEP_ONE_SUCCESS_TYPE,
  SOA_QUOTE_STEP_TWO_SUCCESS_TYPE,
  SOA_QUOTE_STEP_THREE_SUCCESS_TYPE,
  SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE,
  ISSUE_INVOICE_REQUEST,
  ISSUE_INVOICE_SUCCESS,
  ISSUE_INVOICE_FAILURE,
  SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE,
  SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE,
} from "./types";

const soaQuoteReset = () => {
  return { type: SOA_QUOTE_RESET_TYPE };
};

const soaQuoteUpdateStep = (newSoaStep) => {
  return { type: SOA_QUOTE_UPDATE_STEP_TYPE, payload: newSoaStep };
};

const soaQuoteStepOneSuccess = (stepOneData) => {
  return { type: SOA_QUOTE_STEP_ONE_SUCCESS_TYPE, payload: stepOneData };
};

const soaQuoteStepTwoSuccess = (stepTwoData) => {
  return { type: SOA_QUOTE_STEP_TWO_SUCCESS_TYPE, payload: stepTwoData };
};

const soaQuoteStepThreeSuccess = (stepThreeData) => {
  return { type: SOA_QUOTE_STEP_THREE_SUCCESS_TYPE, payload: stepThreeData };
};

const soaElectronicPaymentUpdateStep = (newPaymentStep) => {
  return {
    type: SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE,
    payload: newPaymentStep,
  };
};

const issueInvoiceRequest = () => ({
  type: ISSUE_INVOICE_REQUEST,
});

const issueInvoiceSuccess = (issueInvoiceSuccessData) => ({
  type: ISSUE_INVOICE_SUCCESS,
  payload: issueInvoiceSuccessData,
});

const issueInvoiceFailure = () => ({ type: ISSUE_INVOICE_FAILURE });

function issueInvoice(issueInvoiceData) {
  return function (dispatch, getState) {
    const { token, isLoggedIn, documentType, documentId } = validateToken();
    const { quotation, plate, chassis, census, engine } = getState();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (isLoggedIn) {
      dispatch(issueInvoiceRequest());

      const bodyParametersIssue = {
        documentType,
        documentId,
        plate,
        chassis,
        census,
        engine,
        quotation,
      };

      console.log("*** BODY PARAMETERS ISSUE ", bodyParametersIssue);

      axios
        .post(API_ISSUE_URL, bodyParametersIssue, config)
        .then((response) => {
          invoice(response.data);
        })
        .catch((error) => {
          console.log("*** ERROR: ", error?.response.data.error);
          dispatch(issueInvoiceFailure());
        });
    } else {
      dispatch(issueInvoiceFailure());
    }

    const invoice = (issueData) => {
      const { branch, policy } = issueData;

      const bodyParametersInvoice = {
        branch,
        policy,
      };

      console.log("*** BODY PARAMETERS INVOICE ", bodyParametersInvoice);

      axios
        .post(API_INVOICE_URL, bodyParametersInvoice, config)
        .then((response) => {
          const newData = {
            ...issueInvoiceData,
            ...issueData,
            ...response.data,
          };

          dispatch(issueInvoiceSuccess(newData));
        })
        .catch((error) => {
          console.log("*** ERROR: ", error?.response.data.error);
          dispatch(issueInvoiceFailure());
        });
    };
  };
}

const soaElectronicPaymentStepOneSuccess = () => {
  return {
    type: SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE,
  };
};

const soaElectronicPaymentStepTwoSuccess = () => {
  return {
    type: SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE,
  };
};

export {
  soaQuoteReset,
  soaQuoteUpdateStep,
  soaQuoteStepOneSuccess,
  soaQuoteStepTwoSuccess,
  soaQuoteStepThreeSuccess,
  soaElectronicPaymentUpdateStep,
  issueInvoice,
  soaElectronicPaymentStepOneSuccess,
  soaElectronicPaymentStepTwoSuccess,
};
