import { API_BASE_URL } from "../../../utils/constants";

const API_FILE_UPLOAD_URL = `${API_BASE_URL}/secure/uploadFile`;
const API_PAYMENT_METHODS_URL = `${API_BASE_URL}/secure/paymentMethods`;
const API_BANKS_URL = `${API_BASE_URL}/secure/banks`;
const API_CREDIT_CARDS_URL = `${API_BASE_URL}/secure/creditCards`;
const API_ISSUE_URL = `${API_BASE_URL}/secure/issueSoa`;
const API_INVOICE_URL = `${API_BASE_URL}/secure/invoice`;
const API_SISTARBANC_BACK_URL = `${API_BASE_URL}/sistarbanc`;
const SISTARBANC_URL = process.env.REACT_APP_SISTARBANC_URL;

export {
  API_FILE_UPLOAD_URL,
  API_PAYMENT_METHODS_URL,
  API_BANKS_URL,
  API_CREDIT_CARDS_URL,
  API_ISSUE_URL,
  API_INVOICE_URL,
  API_SISTARBANC_BACK_URL,
  SISTARBANC_URL,
};
