import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { Breakpoint } from "react-socks";

import styles from "./ElectronicPaymentHeader.module.css";
import { validateToken } from "../../login/validateToken";
import bseLogo from "../../../images/bse-logo-negative.png";

function ElectronicPaymentHeader() {
  const [fullName, setFullName] = useState("");
  const [nameInitials, setNameInitials] = useState("");

  useEffect(() => {
    const userPayload = validateToken();
    if (userPayload.isLoggedIn) {
      setFullName(userPayload.publicProfile.fullName);
      setNameInitials(userPayload.publicProfile.nameInitials);
    } else {
      setFullName("");
      setNameInitials("");
    }
  }, []);

  const upperHeader = (
    <>
      {fullName && (
        <div className={styles["username-container"]}>
          <Avatar
            sx={{
              margin: "3px 0px 3px 16px",
              bgcolor: "#e29000",
            }}
          >
            {nameInitials}
          </Avatar>
          <span className={styles.username}>{fullName}</span>
        </div>
      )}
    </>
  );

  return (
    <header className={styles["app-header"]}>
      <Breakpoint medium down>
        <div className={styles["header-container-responsive"]}>
          <img
            src={bseLogo}
            alt="logo BSE"
            className={styles["logo-responsive"]}
          />
          {upperHeader}
        </div>
      </Breakpoint>

      <Breakpoint large up>
        <div className={styles["header-container"]}>
          <img src={bseLogo} alt="logo BSE" className={styles.logo} />
          {upperHeader}
        </div>
      </Breakpoint>

      <div className={styles["title-container"]}>
        <span className={styles["main-title"]}>Pago electrónico</span>
        <span className={styles.line} />
      </div>
    </header>
  );
}

export default ElectronicPaymentHeader;
