import { BrowserRouter, Switch, Route } from "react-router-dom";

import SoaQuoteStepOne from "./stepOne/SoaQuoteStepOne";
import SoaQuoteStepTwo from "./stepTwo/SoaQuoteStepTwo";
import SoaQuoteStepThree from "./stepThree/SoaQuoteStepThree";
import PageNotFound from "./PageNotFound";
import {
  ROUTE_SOA_QUOTE_STEP_ONE,
  ROUTE_SOA_QUOTE_STEP_TWO,
  ROUTE_SOA_QUOTE_STEP_THREE,
} from "./utils/routes";
import {
  ROUTE_ELECTRONIC_PAYMENT_STEP_ONE,
  ROUTE_ELECTRONIC_PAYMENT_STEP_TWO,
  ROUTE_ELECTRONIC_PAYMENT_STEP_THREE,
} from "../electronicPayment/utils/routes";
import PrivateRoute from "../login/PrivateRoute";
import SoaElectronicPaymentStepOne from "../electronicPayment/soa/stepOne/SoaElectronicPaymentStepOne";
import SoaElectronicPaymentStepTwo from "../electronicPayment/soa/stepTwo/SoaElectronicPaymentStepTwo";
import SoaElectronicPaymentStepThree from "../electronicPayment/soa/stepThree/SoaElectronicPaymentStepThree";

function SoaQuote() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route
          exact
          path={`${ROUTE_SOA_QUOTE_STEP_ONE}`}
          component={SoaQuoteStepOne}
        />

        <Route
          exact
          path={`${ROUTE_SOA_QUOTE_STEP_TWO}`}
          component={SoaQuoteStepTwo}
        />

        <Route
          exact
          path={`${ROUTE_SOA_QUOTE_STEP_THREE}`}
          component={SoaQuoteStepThree}
        />

        {/* if the user is logged the component is rendered */}
        <PrivateRoute
          exact
          path={`${ROUTE_ELECTRONIC_PAYMENT_STEP_ONE}`}
          component={SoaElectronicPaymentStepOne}
        />

        {/* if the user is logged the component is rendered */}
        <PrivateRoute
          exact
          path={`${ROUTE_ELECTRONIC_PAYMENT_STEP_TWO}`}
          component={SoaElectronicPaymentStepTwo}
        />

        {/* if the user is logged the component is rendered */}
        <PrivateRoute
          exact
          path={`${ROUTE_ELECTRONIC_PAYMENT_STEP_THREE}`}
          component={SoaElectronicPaymentStepThree}
        />

        <Route component={PageNotFound}></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default SoaQuote;
