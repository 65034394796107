import { SISTARBANC_URL } from "../../utils/urls";

function spe(speData) {
  const divElement = document.querySelector("#divSPE");
  if (divElement) {
    const postData = {
      ...speData,
      idForm: "spe",
      valueAction: SISTARBANC_URL,
      agency: "BSE",
      serviceType: "SEG",
      payload: "PASARELA DE PAGOS MIBSE",
    };
    createData(divElement, postData);
  }
  return true;
}

function createData(objElement, postData) {
  let objForm = appendForm(
    objElement,
    postData.idForm,
    postData.valueAction,
    "post"
  );
  appendInput(objForm, "idBanco", "idBanco", postData.bankId);
  appendInput(objForm, "idTransaccion", "idTransaccion", postData.trn);
  appendInput(objForm, "idOrganismo", "idOrganismo", postData.agency);
  appendInput(objForm, "tipoServicio", "tipoServicio", postData.serviceType);
  appendInput(objForm, "idCuenta", "idCuenta", postData.account);
  appendInput(objForm, "idFactura", "idFactura", postData.invoice);
  appendInput(objForm, "importe", "importe", postData.amount);
  appendInput(
    objForm,
    "importeGravado",
    "importeGravado",
    postData.taxedAmount
  );
  appendInput(
    objForm,
    "consumidorFinal",
    "consumidorFinal",
    postData.finalConsumer
  );
  appendInput(objForm, "moneda", "moneda", postData.currency);
  appendInput(objForm, "fechaVenc", "fechaVenc", postData.dueDate);
  appendInput(
    objForm,
    "fechaLimitePago",
    "fechaLimitePago",
    postData.paydayLimit
  );
  appendInput(objForm, "otroDato", "otroDato", postData.payload);
  appendInput(objForm, "urlVuelta", "urlVuelta", postData.backUrl);
  console.log("*** FORM SUBMITTED: ", objForm.outerHTML);
  objForm.submit();
  return true;
}

function appendForm(objElement, idForm, action, method) {
  const elementForm = document.createElement("form");
  const objForm = objElement.appendChild(elementForm);

  objForm.setAttribute("id", idForm);
  objForm.setAttribute("action", action);
  objForm.setAttribute("method", method);
  return objForm;
}

function appendInput(objElement, id, name, value) {
  const elementInput = document.createElement("input");
  const objInput = objElement.appendChild(elementInput);
  objInput.setAttribute("id", id);
  objInput.setAttribute("name", name);
  objInput.setAttribute("value", value);
  objInput.setAttribute("type", "hidden");
  return objInput;
}

export { spe };
