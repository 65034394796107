import { useSelector } from "react-redux";
import { Breakpoint } from "react-socks";

import styles from "./SoaQuoteDetailStepThree.module.css";
import { getCoverageImages } from "../utils/coverageImages";

function SoaQuoteDetailStepThree() {
  const coverageType = useSelector((state) => state.coverageType);
  const coverageLabel = useSelector((state) => state.coverageLabel);
  const currencySymbol = useSelector((state) => state.currencySymbol);
  const premium = useSelector((state) => state.premium);

  const images = getCoverageImages(coverageType);
  const premiumFormat = (
    <>
      {currencySymbol}
      {premium.toLocaleString("es-ES", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
    </>
  );

  return (
    <>
      <Breakpoint medium down>
        <section className={styles["container-responsive"]}>
          <div className={styles["image-container"]}>
            <img
              src={images.headerImage}
              alt="Cobertura"
              className={styles.image}
            />
          </div>
          <div className={`${styles.text} ${styles.title}`}>
            Tipo de cobertura:
          </div>
          <div className={styles.text}>{coverageLabel}</div>
          <div className={`${styles.text} ${styles.title}`}>
            Total (imp. inc.):
          </div>
          <div className={styles.text}>{premiumFormat}</div>
        </section>
      </Breakpoint>

      <Breakpoint large up>
        <section className={styles.container}>
          <div className={styles["item-1"]}>
            <img
              src={images.headerImage}
              alt="Cobertura"
              className={styles.image}
            />
          </div>
          <div className={styles["item-2"]}>Tipo de cobertura:</div>
          <div className={styles["item-3"]}>Total (imp. inc.):</div>
          <div className={styles["item-4"]}>{coverageLabel}</div>
          <div className={styles["item-5"]}>{premiumFormat}</div>
        </section>
      </Breakpoint>
    </>
  );
}

export default SoaQuoteDetailStepThree;
