import { useSelector } from "react-redux";
import { getFullName } from "../../../login/validateToken";
import { Breakpoint } from "react-socks";

import styles from "./SoaElectronicPaymentPurchaseDetailStepTwo.module.css";

function SoaElectronicPaymentPurchaseDetailStepTwo() {
  const productLabel = useSelector((state) => state.productLabel);
  const coverageLabel = useSelector((state) => state.coverageLabel);
  const quotation = useSelector((state) => state.quotation);
  const invoice = useSelector((state) => state.invoice);
  const currencySymbol = useSelector((state) => state.currencySymbol);
  const premium = useSelector((state) => state.premium);
  const paymentMethodLabel = useSelector((state) => state.paymentMethodLabel);
  const bankLabel = useSelector((state) => state.bankLabel);
  const creditCardLabel = useSelector((state) => state.creditCardLabel);
  const shortFilename1 = useSelector((state) => state.shortFilename1);
  const shortFilename2 = useSelector((state) => state.shortFilename2);
  const shortFilename3 = useSelector((state) => state.shortFilename3);

  const contentLeft = (
    <>
      <div className={`${styles.text} ${styles.title}`}>Producto:</div>
      <div className={styles.text}>{productLabel}</div>
      <div className={`${styles.text} ${styles.title}`}>Cobertura:</div>
      <div className={styles.text}>{coverageLabel}</div>
      <div className={`${styles.text} ${styles.title}`}>N° de cotización:</div>
      <div className={styles.text}>{quotation}</div>
      <div className={`${styles.text} ${styles.title}`}>
        Nombre del contratante:
      </div>
      <div className={styles.text}>{getFullName()}</div>
    </>
  );

  const contentRight = (
    <>
      <div className={`${styles.text} ${styles.title}`}>N° de factura:</div>
      <div className={styles.text}>{invoice}</div>
      <div className={`${styles.text} ${styles.title}`}>Importe:</div>
      <div className={styles.text}>
        {currencySymbol}
        {premium.toLocaleString("es-ES", {
          style: "decimal",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
      </div>
      <div className={`${styles.text} ${styles.title}`}>Medio de pago:</div>
      <div className={styles.text}>
        {paymentMethodLabel} {bankLabel} {creditCardLabel}
      </div>
      <div className={`${styles.text} ${styles.title}`}>
        Imágenes recibidas:
      </div>
      <div className={styles.text}>{shortFilename1}</div>
      <div className={styles.text}>{shortFilename2}</div>
      <div className={styles.text}>{shortFilename3}</div>
    </>
  );

  const content = (
    <div className={styles.container}>
      <section className={styles["container-left"]}>{contentLeft}</section>
      <section className={styles["container-right"]}>{contentRight}</section>
    </div>
  );

  const contentResponsive = (
    <div className={styles["container-responsive"]}>
      <section className={styles["container-left-responsive"]}>
        {contentLeft}
      </section>
      <section className={styles["container-right-responsive"]}>
        {contentRight}
      </section>
    </div>
  );

  return (
    <>
      <Breakpoint medium down>
        {contentResponsive}
      </Breakpoint>

      <Breakpoint large up>
        {content}
      </Breakpoint>
    </>
  );
}

export default SoaElectronicPaymentPurchaseDetailStepTwo;
