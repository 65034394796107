const SOA_QUOTE_RESET_TYPE = "SOA_QUOTE_RESET_TYPE";
const SOA_QUOTE_UPDATE_STEP_TYPE = "SOA_QUOTE_UPDATE_STEP_TYPE";
const SOA_QUOTE_STEP_ONE_SUCCESS_TYPE = "SOA_QUOTE_STEP_ONE_SUCCESS_TYPE";
const SOA_QUOTE_STEP_TWO_SUCCESS_TYPE = "SOA_QUOTE_STEP_TWO_SUCCESS_TYPE";
const SOA_QUOTE_STEP_THREE_SUCCESS_TYPE = "SOA_QUOTE_STEP_THREE_SUCCESS_TYPE";

const SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE =
  "SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE";
const ISSUE_INVOICE_REQUEST = "ISSUE_INVOICE_REQUEST";
const ISSUE_INVOICE_SUCCESS = "ISSUE_INVOICE_SUCCESS";
const ISSUE_INVOICE_FAILURE = "ISSUE_INVOICE_FAILURE";
const SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE =
  "SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE";
const SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE =
  "SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE";

export {
  SOA_QUOTE_RESET_TYPE,
  SOA_QUOTE_UPDATE_STEP_TYPE,
  SOA_QUOTE_STEP_ONE_SUCCESS_TYPE,
  SOA_QUOTE_STEP_TWO_SUCCESS_TYPE,
  SOA_QUOTE_STEP_THREE_SUCCESS_TYPE,
  SOA_ELECTRONIC_PAYMENT_UPDATE_STEP_TYPE,
  ISSUE_INVOICE_REQUEST,
  ISSUE_INVOICE_SUCCESS,
  ISSUE_INVOICE_FAILURE,
  SOA_ELECTRONIC_PAYMENT_STEP_ONE_SUCCESS_TYPE,
  SOA_ELECTRONIC_PAYMENT_STEP_TWO_SUCCESS_TYPE,
};
