const ELECTRONIC_PAYMENT_INIT = 0;
const ELECTRONIC_PAYMENT_STEP_ONE = 1;
const ELECTRONIC_PAYMENT_STEP_TWO = 2;
const ELECTRONIC_PAYMENT_STEP_THREE = 3;
const ELECTRONIC_PAYMENT_END = 5;

export {
  ELECTRONIC_PAYMENT_INIT,
  ELECTRONIC_PAYMENT_STEP_ONE,
  ELECTRONIC_PAYMENT_STEP_TWO,
  ELECTRONIC_PAYMENT_STEP_THREE,
  ELECTRONIC_PAYMENT_END,
};
