import { Route } from "react-router-dom";
import Login from "../login/Login";
import { validateToken } from "./validateToken";

// Boilerplate: if the user is logged the component is rendered
function PrivateRoute({ component: Component, ...otherProps }) {
  return (
    <Route
      // <Route component> takes precedence over <Route render> so we can't use both
      // in the same <Route>.
      // ...otherProps has all the properties (exact, path, etc.) except property component.
      {...otherProps}
      // The render prop function has access to all the Route props (match, location and history).
      // https://v5.reactrouter.com/web/api/Route
      render={(routeProps) =>
        validateToken().isLoggedIn ? (
          <Component {...routeProps} />
        ) : (
          <Login referrer={routeProps.location.pathname} />
        )
      }
    />
  );
}

export default PrivateRoute;
