import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Redirect } from "react-router";
import Container from "@mui/material/Container";

import SoaQuoteDetailStepTwo from "../stepTwo/SoaQuoteDetailStepTwo";
import SoaQuoteDetailStepThree from "./SoaQuoteDetailStepThree";
import SoaQuoteFormStepThree from "./SoaQuoteFormStepThree";
import { soaQuoteUpdateStep } from "../../../redux/actions";
import SoaFooter from "../template/SoaFooter";
import SoaBackButton from "../template/SoaBackButton";
import SoaQuoteSteps from "../SoaQuoteSteps";
import { ROUTE_SOA_QUOTE_STEP_ONE } from "../utils/routes";
import SoaHeader from "../template/SoaHeader";
import { SOA_QUOTE_STEP_THREE } from "../utils/constants";

function SoaQuoteStepThree() {
  const soaQuoteStep = useSelector((state) => state.soaQuoteStep);
  console.log("*** SOA STEP: ", soaQuoteStep);

  const dispatch = useDispatch();

  useEffect(() => {
    // This is the case where the user, being in step n, returns to this screen
    dispatch(soaQuoteUpdateStep(SOA_QUOTE_STEP_THREE));
  }, [dispatch]);

  return (
    <>
      {soaQuoteStep >= SOA_QUOTE_STEP_THREE ? (
        <>
          <SoaHeader />
          <Container fixed>
            <div className="app">
              <SoaQuoteSteps soaQuoteStep={soaQuoteStep} />
              <SoaQuoteDetailStepThree />
              <SoaQuoteDetailStepTwo />
              <SoaQuoteFormStepThree />
              <SoaBackButton />
            </div>
          </Container>
          <SoaFooter />
        </>
      ) : (
        <Redirect to={ROUTE_SOA_QUOTE_STEP_ONE} />
      )}
    </>
  );
}

export default SoaQuoteStepThree;
