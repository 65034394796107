import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import axios from "axios";
import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { Breakpoint } from "react-socks";

import styles from "./Login.module.css";
import {
  API_DOCUMENT_TYPES_URL,
  API_LOGIN_URL,
  API_USERS_URL,
} from "./utils/urls";
import { USER_TOKEN } from "../../utils/constants";

function Login({ referrer }) {
  console.log("*** PROP REFERRER: ", referrer);

  const [loginError, setLoginError] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [validUsers, setValidUsers] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  useEffect(() => {
    const getDocumentTypes = async () => {
      const response = await axios({
        method: "get",
        url: API_DOCUMENT_TYPES_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setDocumentTypes(response.data);
    };

    const getValidUsers = async () => {
      const response = await axios({
        method: "get",
        url: API_USERS_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setValidUsers(response.data);
    };

    getDocumentTypes();
    getValidUsers();
  }, []);

  const onSubmit = async (loginData) => {
    const { documentType, documentId, password } = loginData;
    const documentTypeLabel = documentTypes.find(
      (documentType) => documentType.item === loginData.documentType
    ).label;

    try {
      const response = await axios({
        method: "post",
        url: API_LOGIN_URL,
        data: {
          documentType,
          documentTypeLabel,
          documentId,
          password,
        },
      });
      setLoginError(false);
      console.log("*** RESPONSE", response);

      const { token } = response.data;
      sessionStorage.setItem(USER_TOKEN, token);

      console.log("*** REDIRECTING TO ", referrer);
      history.push(referrer);
    } catch (error) {
      console.log("*** ERROR", error?.response.data.error);
      setLoginError(true);
    }
  };

  const onError = (errors, e) => {
    setLoginError(false);
    console.log("*** ERROR: ", errors);
  };

  const documentTypesOptions = documentTypes.map((documentType) => (
    <option key={documentType.id} value={documentType.item}>
      {documentType.label}
    </option>
  ));

  const users = validUsers.map((validUser) => (
    <span key={validUser.id} className={styles.note}>
      {validUser.documentTypeLabel} {validUser.documentId} {validUser.password}
    </span>
  ));

  const formContent = (
    <>
      {loginError && (
        <Alert
          severity="error"
          sx={{ borderRadius: "20px", margin: "10px 0px" }}
        >
          Documento y/o contraseña incorrecta
        </Alert>
      )}

      <label className={styles.label} htmlFor="documentType">
        Tipo de documento:<span className={styles.aster}>*</span>
      </label>
      <select
        className={styles.input}
        {...register("documentType", { required: true })}
      >
        <option value="">Seleccione...</option>
        {documentTypesOptions}
      </select>

      {errors.documentType?.type === "required" && (
        <Alert
          severity="error"
          sx={{ borderRadius: "20px", margin: "10px 0px" }}
        >
          Debe seleccionar el tipo de documento
        </Alert>
      )}

      <label className={styles.label} htmlFor="documentId">
        Número de documento:<span className={styles.aster}>*</span>
      </label>
      <input
        type="text"
        placeholder="Ingrese el número de documento"
        autoComplete="username"
        className={styles.input}
        {...register("documentId", { required: true })}
      />

      {errors.documentId?.type === "required" && (
        <Alert
          severity="error"
          sx={{ borderRadius: "20px", margin: "10px 0px" }}
        >
          Debe ingresar el número de documento
        </Alert>
      )}

      <label className={styles.label} htmlFor="password">
        Contraseña:<span className={styles.aster}>*</span>
      </label>
      <input
        type="password"
        placeholder="Ingrese su contraseña "
        autoComplete="current-password"
        className={styles.input}
        {...register("password", { required: true })}
      />

      {errors.password?.type === "required" && (
        <Alert
          severity="error"
          sx={{ borderRadius: "20px", margin: "10px 0px" }}
        >
          Debe ingresar una contraseña
        </Alert>
      )}

      <input className={styles.mainButton} type="submit" value="Ingresar" />
      <div className={styles.line} />
      <span className={styles.note}>
        <strong>Usuarios válidos</strong>
      </span>
      {users}
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.back}>
          <div className={styles.head} />
          <div className={styles.main} />
          <div className={styles.foot} />
        </div>
      </div>

      <div className={styles.overlay}>
        <Breakpoint medium down>
          <form
            className={styles["form-responsive"]}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {formContent}
          </form>
        </Breakpoint>

        <Breakpoint large up>
          <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {formContent}
          </form>
        </Breakpoint>
      </div>
    </div>
  );
}

export default Login;
