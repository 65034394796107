import { useSelector } from "react-redux";
import { Breakpoint } from "react-socks";

import styles from "./SoaElectronicPaymentDetailStepThree.module.css";

function SoaElectronicPaymentDetailStepThree() {
  const policy = useSelector((state) => state.policy);
  const trn = useSelector((state) => state.trn);

  return (
    <>
      <Breakpoint medium down>
        <section className={styles["container-responsive"]}>
          <div className={`${styles.text} ${styles.title}`}>
            Número de póliza emitida:
          </div>
          <div className={styles.text}>{policy}</div>
          <div className={`${styles.text} ${styles.title}`}>Transacción:</div>
          <div className={styles.text}>{trn.substr(0, trn.length / 2)}</div>
          <div className={styles.text}>{trn.substr(trn.length / 2)}</div>
        </section>
      </Breakpoint>

      <Breakpoint large up>
        <section className={styles.container}>
          <div className={`${styles.text} ${styles.title}`}>
            Número de póliza emitida:
          </div>
          <div className={`${styles.text} ${styles.title}`}>Transacción:</div>
          <div className={styles.text}>{policy}</div>
          <div className={styles.text}>{trn}</div>
        </section>
      </Breakpoint>
    </>
  );
}

export default SoaElectronicPaymentDetailStepThree;
