import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { useEffect } from "react";
import Container from "@mui/material/Container";

import ElectronicPaymentSteps from "../../ElectronicPaymentSteps";
import ElectronicPaymentHeader from "../../template/ElectronicPaymentHeader";
import ElectronicPaymentFooter from "../../template/ElectronicPaymentFooter";
import SoaBackButton from "../../../soaQuote/template/SoaBackButton";
import { ROUTE_SOA_QUOTE_STEP_ONE } from "../../../soaQuote/utils/routes";
import { soaElectronicPaymentUpdateStep } from "../../../../redux/actions";
import { SOA_QUOTE_STEP_FOUR } from "../../../soaQuote/utils/constants";
import { ELECTRONIC_PAYMENT_STEP_TWO } from "../../utils/constants";
import SoaElectronicPaymentPurchaseDetailStepTwo from "./SoaElectronicPaymentPurchaseDetailStepTwo";
import SoaElectronicPaymentFormStepTwo from "./SoaElectronicPaymentFormStepTwo";

function SoaElectronicPaymentStepTwo() {
  const soaQuoteStep = useSelector((state) => state.soaQuoteStep);
  const paymentStep = useSelector((state) => state.paymentStep);
  console.log("*** SOA STEP: ", soaQuoteStep);
  console.log("*** PAYMENT STEP: ", paymentStep);

  const dispatch = useDispatch();

  useEffect(() => {
    // This is the case where the user, being in step n, returns to this screen
    dispatch(soaElectronicPaymentUpdateStep(ELECTRONIC_PAYMENT_STEP_TWO));
  }, [dispatch]);

  return (
    <>
      {soaQuoteStep === SOA_QUOTE_STEP_FOUR &&
      paymentStep >= ELECTRONIC_PAYMENT_STEP_TWO ? (
        <>
          <ElectronicPaymentHeader />
          <Container fixed>
            <div className="app">
              <ElectronicPaymentSteps paymentStep={paymentStep} />
              <SoaElectronicPaymentPurchaseDetailStepTwo />
              <SoaElectronicPaymentFormStepTwo />
              <SoaBackButton />
            </div>
          </Container>
          <ElectronicPaymentFooter />
        </>
      ) : (
        <Redirect to={ROUTE_SOA_QUOTE_STEP_ONE} />
      )}
    </>
  );
}

export default SoaElectronicPaymentStepTwo;
