import CircularProgress from "@mui/material/CircularProgress";
import { deepOrange } from "@mui/material/colors";

function Loading({ size }) {
  let sizePx;
  switch (size) {
    case "small":
      sizePx = 30;
      break;
    case "medium":
      sizePx = 60;
      break;
    case "big":
      sizePx = 90;
      break;
    default:
      sizePx = 30;
      break;
  }
  return (
    <CircularProgress
      size={sizePx}
      sx={{
        color: deepOrange[500],
      }}
    />
  );
}

export default Loading;
