import headerSoa from "../../../images/headerSOA.png";
import textSoa from "../../../images/textSOA.png";
import headerSoaPlus from "../../../images/headerSOAPlus.png";
import textSoaPlus from "../../../images/textSOAPlus.png";
import headerSoaPlusVida from "../../../images/headerSOAPlusVida.png";
import textSoaPlusVida from "../../../images/textSOAPlusVida.png";
import headerSoaVida from "../../../images/headerSOAVida.png";
import textSoaVida from "../../../images/textSOAVida.png";

function getCoverageImages(coverageItem) {
  const coverageImages = [
    {
      id: 1,
      item: "SOA",
      headerImage: headerSoa,
      textImage: textSoa,
    },
    {
      id: 2,
      item: "SOA_PLUS",
      headerImage: headerSoaPlus,
      textImage: textSoaPlus,
    },
    {
      id: 3,
      item: "SOA_PLUS_VIDA",
      headerImage: headerSoaPlusVida,
      textImage: textSoaPlusVida,
    },
    {
      id: 4,
      item: "SOA_VIDA",
      headerImage: headerSoaVida,
      textImage: textSoaVida,
    },
  ];

  return coverageImages.find(
    (coverageImage) => coverageImage.item === coverageItem
  );
}

export { getCoverageImages };
