import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert, Container } from "@mui/material";

import { soaQuoteStepOneSuccess } from "../../../redux/actions";
import { RECAPTCHA_SITE_KEY } from "../../../utils/constants";
import { ROUTE_SOA_QUOTE_STEP_TWO } from "../utils/routes";
import { API_BRANDS_URL, API_VEHICLE_TYPES_URL } from "../utils/urls";
import styles from "./SoaQuoteFormStepOne.module.css";

function SoaQuoteFormStepOne() {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    const getVehicleTypes = async () => {
      const response = await axios({
        method: "get",
        url: API_VEHICLE_TYPES_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setVehicleTypes(response.data);
    };

    const getBrands = async () => {
      const response = await axios({
        method: "get",
        url: API_BRANDS_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setBrands(response.data);
    };

    getVehicleTypes();
    getBrands();
  }, []);

  const onSubmit = (data) => {
    if (captchaValue !== null) {
      const vehicleTypeLabel = vehicleTypes.find(
        (vehicleType) => vehicleType.item === data.vehicleType
      ).label;

      const brandLabel = brands.find(
        (brand) => brand.item === data.brand
      ).label;

      const newData = {
        ...data,
        vehicleTypeLabel,
        brandLabel,
      };
      dispatch(soaQuoteStepOneSuccess(newData)); // Add new data to the store
      history.push(ROUTE_SOA_QUOTE_STEP_TWO);
    }

    setCaptchaError(captchaValue === null);
  };

  const onError = (errors, e) => {
    setCaptchaError(captchaValue === null);
    console.log("*** ERROR: ", errors);
  };

  const handleOnChangeCaptcha = (value) => {
    setCaptchaValue(value);
    setCaptchaError(value === null);
  };

  const vehicleTypesOptions = vehicleTypes.map((vehicleType) => (
    <option key={vehicleType.id} value={vehicleType.item}>
      {vehicleType.label}
    </option>
  ));

  const brandsOptions = brands.map((brand) => (
    <option key={brand.id} value={brand.item}>
      {brand.label}
    </option>
  ));

  const yearValidation = {
    max: 2024,
    min: 1900,
  };

  return (
    <Container fixed>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
        <label className={styles.label} htmlFor="vehicleType">
          Tipo de vehículo:<span className={styles.aster}>*</span>
        </label>
        <select
          autoFocus
          className={styles.input}
          {...register("vehicleType", { required: true })}
        >
          <option value="">Seleccione...</option>
          {vehicleTypesOptions}
        </select>

        {errors.vehicleType?.type === "required" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            Debe seleccionar el tipo de vehículo
          </Alert>
        )}

        <label className={styles.label} htmlFor="brand">
          Marca:<span className={styles.aster}>*</span>
        </label>
        <select
          className={styles.input}
          {...register("brand", { required: true })}
        >
          <option value="">Seleccione...</option>
          {brandsOptions}
        </select>

        {errors.brand?.type === "required" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            Debe seleccionar la marca del vehículo
          </Alert>
        )}

        <label className={styles.label} htmlFor="year">
          Año:<span className={styles.aster}>*</span>
        </label>
        <input
          type="number"
          placeholder="Ingrese el año del vehículo"
          className={styles.input}
          {...register("year", {
            required: true,
            max: yearValidation.max,
            min: yearValidation.min,
          })}
        />

        {errors.year?.type === "required" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            Debe ingresar el año del vehículo
          </Alert>
        )}

        {errors.year?.type === "min" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El año debe no debe ser menor a {yearValidation.min}
          </Alert>
        )}

        {errors.year?.type === "max" && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            El año no debe ser mayor a {yearValidation.max}
          </Alert>
        )}

        <p />
        <div className={styles.captcha}>
          <ReCAPTCHA
            id="captcha"
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleOnChangeCaptcha}
            hl="es"
          />
        </div>

        {captchaError && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            Debe seleccionar la casilla de verificación
          </Alert>
        )}

        <input className={styles.mainButton} type="submit" value="Cotizar" />
      </form>
    </Container>
  );
}

export default SoaQuoteFormStepOne;
