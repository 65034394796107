const SOA_QUOTE_STEP_ONE = 1;
const SOA_QUOTE_STEP_TWO = 2;
const SOA_QUOTE_STEP_THREE = 3;
const SOA_QUOTE_STEP_FOUR = 4;

export {
  SOA_QUOTE_STEP_ONE,
  SOA_QUOTE_STEP_TWO,
  SOA_QUOTE_STEP_THREE,
  SOA_QUOTE_STEP_FOUR,
};
