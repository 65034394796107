import { Link } from "react-router-dom";
import { Breakpoint } from "react-socks";

import styles from "./SoaQuoteSteps.module.css";
import tick from "../../images/tick.png";
import {
  ROUTE_SOA_QUOTE_STEP_ONE,
  ROUTE_SOA_QUOTE_STEP_TWO,
  ROUTE_SOA_QUOTE_STEP_THREE,
} from "./utils/routes";
import { ROUTE_ELECTRONIC_PAYMENT_STEP_ONE } from "../electronicPayment/utils/routes";

function getClassName(soaQuoteStep, currentItem) {
  return soaQuoteStep >= currentItem
    ? `${styles["dot-item"]} ${styles["dot-text"]} ${styles["dot-selected"]}`
    : `${styles["dot-item"]} ${styles["dot-text"]}`;
}

function SoaQuoteSteps({ soaQuoteStep }) {
  const line = <div className={styles["line-item"]}></div>;
  const circles = [
    {
      id: 1,
      link: ROUTE_SOA_QUOTE_STEP_ONE,
      text: "Tipo de Vehículo",
      line: true,
    },
    {
      id: 2,
      link: ROUTE_SOA_QUOTE_STEP_TWO,
      text: "Cotización",
      line: true,
    },
    {
      id: 3,
      link: ROUTE_SOA_QUOTE_STEP_THREE,
      text: "Información del Vehículo",
      line: true,
    },
    {
      id: 4,
      link: ROUTE_ELECTRONIC_PAYMENT_STEP_ONE,
      text: "Contratar",
      line: false,
    },
  ];

  const steps = circles.map((circle) => (
    <div key={circle.id} className={styles.steps}>
      {circle.id <= soaQuoteStep ? (
        <Link to={circle.link}>
          <div className={getClassName(soaQuoteStep, circle.id)}>
            {circle.id === soaQuoteStep ? (
              <>{circle.id} </>
            ) : (
              <img src={tick} alt="Listo" className={styles.tick} />
            )}
          </div>
        </Link>
      ) : (
        <div className={getClassName(soaQuoteStep, circle.id)}>{circle.id}</div>
      )}
      <div className={styles["text-item"]}>{circle.text}</div>
      {circle.line && line}
    </div>
  ));

  const stepsResponsive = circles.map((circle) => (
    <div key={circle.id} className={styles["steps-responsive"]}>
      {circle.id <= soaQuoteStep ? (
        <Link to={circle.link}>
          <div className={getClassName(soaQuoteStep, circle.id)}>
            {circle.id === soaQuoteStep ? (
              <>{circle.id} </>
            ) : (
              <img src={tick} alt="Listo" className={styles.tick} />
            )}
          </div>
        </Link>
      ) : (
        <div className={getClassName(soaQuoteStep, circle.id)}>{circle.id}</div>
      )}
      <div className={styles["text-item"]}>{circle.text}</div>
    </div>
  ));

  return (
    <>
      <Breakpoint medium down>
        <div className={styles["main-container"]}>
          <div className={styles["inner-container-responsive"]}>
            {stepsResponsive}
          </div>
        </div>
      </Breakpoint>

      <Breakpoint large up>
        <div className={styles["main-container"]}>
          <div className={styles["inner-container"]}>{steps}</div>
        </div>
      </Breakpoint>
    </>
  );
}

export default SoaQuoteSteps;
