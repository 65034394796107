import { useDispatch } from "react-redux";
import { soaQuoteReset } from "../../../redux/actions";
import { Breakpoint } from "react-socks";

import styles from "./SoaBackButton.module.css";

function SoaBackButton() {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(soaQuoteReset()); // Resets the store
  };

  const backButton = (
    <button
      className={styles.backButton}
      onClick={handleOnClick}
    >{`< volver`}</button>
  );

  return (
    <>
      <Breakpoint medium down>
        <div className={styles["main-container"]}>
          <div className={styles["inner-container-responsive"]}>
            {backButton}
          </div>
        </div>
      </Breakpoint>

      <Breakpoint large up>
        <div className={styles["main-container"]}>
          <div className={styles["inner-container"]}>{backButton}</div>
        </div>
      </Breakpoint>
    </>
  );
}

export default SoaBackButton;
