import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import styles from "./SoaQuoteCoverageStepTwo.module.css";
import { getCoverageImages } from "../utils/coverageImages";
import Loading from "../../Loading";
import { API_COVERAGES_URL } from "../utils/urls";
import { soaQuoteStepTwoSuccess } from "../../../redux/actions";
import { ROUTE_SOA_QUOTE_STEP_THREE } from "../utils/routes";

function SoaQuoteCoverageStepTwo({ coverage }) {
  const [rate, setRate] = useState(null);

  const dispatch = useDispatch();

  const history = useHistory();

  const vehicleType = useSelector((state) => state.vehicleType);
  const brand = useSelector((state) => state.brand);
  const year = useSelector((state) => state.year);

  const handleOnClick = () => {
    dispatch(soaQuoteStepTwoSuccess(rate)); // Add new data to the store
    history.push(ROUTE_SOA_QUOTE_STEP_THREE);
  };

  const images = getCoverageImages(coverage.item);
  const header = images.headerImage;
  const text = images.textImage;

  // "Warning: Can't perform a React state update on an unmounted component.
  // This is a no-op, but it indicates a memory leak in your application.
  // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function".
  useEffect(() => {
    // Using a cancel token factory to cancel a request.
    // https://axios-http.com/docs/cancellation
    const source = axios.CancelToken.source();

    const getRate = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${API_COVERAGES_URL}/${coverage.item}`,
          data: {
            vehicleType: vehicleType,
            brand: brand,
            year: year,
          },
          cancelToken: source.token, // Sending the cancel token
        });
        console.log("*** RESPONSE: ", response.data);
        setRate(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Axios request canceled by an anxious user");
        } else {
          console.log("*** ERROR", error?.response.data.error);
        }
      }
    };

    getRate();

    // Similar to componentWillUnmount
    return () => {
      console.log("Component will unmount");
      source.cancel("Axios request canceled");
    };
  }, [brand, coverage.item, vehicleType, year]);

  return (
    <>
      {!rate ? (
        <Loading size="medium" />
      ) : (
        <article className={styles.article}>
          <div className={styles["image-container"]}>
            <img src={`${header}`} alt="Cotización" className={styles.image} />
          </div>
          <div className={styles["title-container"]}>
            <span className={styles.title}>{rate.coverageLabel}</span>
          </div>
          <div className={styles["premium-container"]}>
            <p className={styles.imp}>Total (imp. inc.)</p>
            <span className={styles.premium}>
              {rate.currencySymbol}
              {rate.premium.toLocaleString("es-ES", {
                style: "decimal",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
          <img
            src={`${text}`}
            alt="Caracterísitcas"
            className={styles.description}
          />
          <button className={styles.mainButton} onClick={handleOnClick}>
            Contratar
          </button>
        </article>
      )}
    </>
  );
}

export default SoaQuoteCoverageStepTwo;
