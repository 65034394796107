import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import Container from "@mui/material/Container";
import { Breakpoint } from "react-socks";

import { API_COVERAGES_URL } from "../utils/urls";
import SoaQuoteDetailStepTwo from "./SoaQuoteDetailStepTwo";
import SoaQuoteCoverageStepTwo from "./SoaQuoteCoverageStepTwo";
import styles from "./SoaQuoteStepTwo.module.css";
import { soaQuoteUpdateStep } from "../../../redux/actions";
import SoaFooter from "../template/SoaFooter";
import SoaBackButton from "../template/SoaBackButton";
import SoaQuoteSteps from "../SoaQuoteSteps";
import { ROUTE_SOA_QUOTE_STEP_ONE } from "../utils/routes";
import SoaHeader from "../template/SoaHeader";
import { SOA_QUOTE_STEP_TWO } from "../utils/constants";

function SoaQuoteStepTwo() {
  const soaQuoteStep = useSelector((state) => state.soaQuoteStep);
  console.log("*** SOA STEP: ", soaQuoteStep);

  const [coverages, setCoverages] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getCoverages = async () => {
      const response = await axios({
        method: "get",
        url: API_COVERAGES_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setCoverages(response.data);
    };

    getCoverages();

    // This is the case where the user, being in step n, returns to this screen
    dispatch(soaQuoteUpdateStep(SOA_QUOTE_STEP_TWO));
  }, [dispatch]);

  const card = (
    <>
      {coverages.map((coverage) => {
        return (
          <SoaQuoteCoverageStepTwo key={coverage.id} coverage={coverage} />
        );
      })}
    </>
  );

  return (
    <>
      {soaQuoteStep >= SOA_QUOTE_STEP_TWO ? (
        <>
          <SoaHeader />
          <Container fixed>
            <div className="app">
              <SoaQuoteSteps soaQuoteStep={soaQuoteStep} />
              <SoaQuoteDetailStepTwo />
              <Breakpoint medium down>
                <div className={styles["product-container-responsive"]}>
                  {card}
                </div>
              </Breakpoint>

              <Breakpoint large up>
                <div className={styles["product-container"]}>{card}</div>
              </Breakpoint>
              <SoaBackButton />
            </div>
          </Container>
          <SoaFooter />
        </>
      ) : (
        <Redirect to={ROUTE_SOA_QUOTE_STEP_ONE} />
      )}
    </>
  );
}

export default SoaQuoteStepTwo;
