import jwt_decode from "jwt-decode";

import { USER_TOKEN } from "../../utils/constants";

function validateToken() {
  let userPayload = {};
  let isLoggedIn = false;
  const token = sessionStorage.getItem(USER_TOKEN);

  if (token) {
    userPayload = jwt_decode(token);

    // JWT expiration is in seconds
    if (userPayload.exp * 1000 < new Date().getTime()) {
      console.log("*** TOKEN EXPIRED!");
      sessionStorage.removeItem(USER_TOKEN);
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
    }
  }
  return { ...userPayload, token: token, isLoggedIn: isLoggedIn };
}

// Returns user's fullName without doing any validation
function getFullName() {
  let fullName = "";
  const token = sessionStorage.getItem(USER_TOKEN);
  if (token) {
    fullName = jwt_decode(token).publicProfile.fullName;
  }
  return fullName;
}

// Returns user's email without doing any validation
function getEMail() {
  let email = "";
  const token = sessionStorage.getItem(USER_TOKEN);
  if (token) {
    email = jwt_decode(token).publicProfile.email;
  }
  return email;
}

export { validateToken, getFullName, getEMail };
