import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import { API_FILE_UPLOAD_URL } from "../../utils/urls";
import { validateToken } from "../../../login/validateToken";
import styles from "./SoaElectronicPaymentFileUploadStepOne.module.css";

function SoaElectronicPaymentFileUploadStepOne({ maxFiles, setFilesUploaded }) {
  const [filesDone, setFilesDone] = useState([]);

  const getUploadParams = ({ file, meta }) => {
    const url = API_FILE_UPLOAD_URL;
    const body = new FormData();
    body.append("fileField", file);
    const headers = {
      Authorization: `Bearer ${validateToken().token}`,
    };
    return {
      url,
      meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` },
      body,
      headers,
    };
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
    if (status === "done") {
      let files = [];
      files.push(...filesDone, meta.name);
      setFilesDone(files);
      setFilesUploaded(files);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["title-container"]}>
        <span className={styles.title}>
          Adjunte tres imágenes de su automóvil
        </span>
      </div>

      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={maxFiles}
        accept="image/*"
        inputWithFilesContent={(files) => "Adjuntar"}
        inputContent={(files, extra) =>
          extra.reject
            ? "Únicamente archivos de imagen"
            : "Arrastre y suelte imágenes o presione aquí"
        }
        styles={{
          dropzone: {
            overflow: "auto",
            borderRadius: "60px",
            background: "#f4f6fa",
            border: "1px solid #e5e5e5",
            boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "10px",
            margin: "20px 0px",
          },
          dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
          inputLabelWithFiles: {
            borderRadius: "50px",
            alignSelf: "center",
            background: "#e29000",
            color: "white",
            textTransform: "uppercase",
            padding: "10px",
            fontSize: "16px",
            fontWeight: "100",
            letterSpacing: "5px",
            textIndent: "10px",
          },
          inputLabel: (files, extra) =>
            extra.reject
              ? {
                  color: "red",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "18px",
                }
              : {
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "18px",
                  color: "#6b6b6b",
                  margin: "0px 20px",
                  textAlign: "center",
                },
        }}
        multiple={true}
        maxSizeBytes={2 * 1024 * 1024}
      />
    </div>
  );
}

export default SoaElectronicPaymentFileUploadStepOne;
