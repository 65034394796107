import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";

import { ROUTE_SOA_QUOTE_STEP_ONE } from "./utils/routes";

function PageNotFound() {
  const location = useLocation();

  console.log("*** PAGE NOT FOUND: ", location.pathname);
  console.log("*** REDIRECTING TO: ", ROUTE_SOA_QUOTE_STEP_ONE);

  return <Redirect to={ROUTE_SOA_QUOTE_STEP_ONE} />;
}

export default PageNotFound;
