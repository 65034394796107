import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Backdrop, Alert } from "@mui/material";
import { Breakpoint } from "react-socks";

import styles from "./SoaElectronicPaymentFormStepTwo.module.css";
import { soaElectronicPaymentStepTwoSuccess } from "../../../../redux/actions";
import { spe } from "./spe";
import Loading from "../../../Loading";
import { API_SISTARBANC_BACK_URL } from "../../utils/urls";
import {
  ROUTE_ELECTRONIC_PAYMENT_STEP_TWO,
  ROUTE_ELECTRONIC_PAYMENT_STEP_THREE,
} from "../../utils/routes";

function SoaElectronicPaymentFormStepTwo() {
  const [isLoading, setIsLoading] = useState(false);

  const invoice = useSelector((state) => state.invoice);
  const trn = useSelector((state) => state.trn);
  const bank = useSelector((state) => state.bank);
  const creditCard = useSelector((state) => state.creditCard);
  const account = useSelector((state) => state.account);
  const amount = useSelector((state) => state.amount);
  const taxedAmount = useSelector((state) => state.taxedAmount);
  const finalConsumer = useSelector((state) => state.finalConsumer);
  const currency = useSelector((state) => state.currency);
  const dueDate = useSelector((state) => state.dueDate);
  const paydayLimit = useSelector((state) => state.paydayLimit);
  const paymentGateway = useSelector((state) => state.paymentGateway);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const history = useHistory();

  const onSubmit = (data) => {
    // Sistarbanc
    const TIME_OUT = 1000;
    const bankId = bank ? bank : creditCard;

    setIsLoading(true);
    const payment = () => {
      dispatch(soaElectronicPaymentStepTwoSuccess()); // No need to add more data to the store.
      if (paymentGateway) {
        postSpe();
      } else {
        finalStep();
      }
    };

    const postSpe = () => {
      spe({
        trn,
        bankId,
        invoice,
        account,
        amount,
        taxedAmount,
        finalConsumer,
        currency,
        dueDate,
        paydayLimit,
        backUrl: `${API_SISTARBANC_BACK_URL}?referrer=${window.location.href.replace(
          ROUTE_ELECTRONIC_PAYMENT_STEP_TWO,
          ROUTE_ELECTRONIC_PAYMENT_STEP_THREE
        )}`,
      });
    };

    const finalStep = () => {
      history.push(ROUTE_ELECTRONIC_PAYMENT_STEP_THREE);
    };

    setTimeout(payment, TIME_OUT);
  };

  const handleClose = () => {
    console.log("*** BACKDROP CLICK");
  };

  const TERMS =
    "https://www.bse.com.uy/wps/wcm/connect/a721fbea-" +
    "aadf-46e2-9ede-ed7d64e5658e/223-SOA-CG.pdf?MOD=A" +
    "JPERES&amp;CONVERT_TO=url&amp;CACHEID=ROOTWORKSP" +
    "ACE-a721fbea-aadf-46e2-9ede-ed7d64e5658e-mnpON5k";

  const content = (
    <>
      <div className={styles.title}>
        Términos y condiciones:<span className={styles.aster}>*</span>
      </div>
      <div className={styles.text}>
        <input
          autoFocus
          className={styles.check}
          type="checkbox"
          {...register("term1", { required: true })}
        />
        Acepto los Términos y condiciones de la póliza que se presentan{" "}
        <a target="_blank" rel="noreferrer" href={TERMS}>
          aquí.
        </a>
      </div>
      <div className={styles.text}>
        <input
          className={styles.check}
          type="checkbox"
          {...register("term2", { required: true })}
        />
        Para que el seguro entre en vigencia y el usuario pueda hacer uso de su
        cobertura, debe abonar el seguro en línea, una vez que contrate el
        mismo. La factura de previo pago presenta el vencimiento por defecto, el
        mismo día de la emisión.
      </div>
    </>
  );

  const errorContent = (
    <>
      {(errors.term1?.type === "required" ||
        errors.term2?.type === "required") && (
        <Alert
          severity="error"
          sx={{ borderRadius: "20px", margin: "10px 0px" }}
        >
          Debe aceptar los términos y condiciones
        </Alert>
      )}
    </>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Breakpoint medium down>
        <div className={styles["container-responsive"]}>{content}</div>
        <div className={styles["error-container-responsive"]}>
          {errorContent}
        </div>
      </Breakpoint>

      <Breakpoint large up>
        <div className={styles.container}>{content}</div>
        <div className={styles["error-container"]}>{errorContent}</div>
      </Breakpoint>

      <input className={styles.mainButton} type="submit" value="Pagar" />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={handleClose}
      >
        <Loading size="big" />
        <div id="divSPE" />
      </Backdrop>
    </form>
  );
}

export default SoaElectronicPaymentFormStepTwo;
