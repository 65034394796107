import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import Container from "@mui/material/Container";

import ElectronicPaymentSteps from "../../ElectronicPaymentSteps";
import ElectronicPaymentHeader from "../../template/ElectronicPaymentHeader";
import ElectronicPaymentFooter from "../../template/ElectronicPaymentFooter";
import SoaElectronicPaymentDetailStepThree from "./SoaElectronicPaymentDetailStepThree";
import SoaElectronicPaymentSuccessStepThree from "./SoaElectronicPaymentSuccessStepThree";
import SoaBackButton from "../../../soaQuote/template/SoaBackButton";
import { ROUTE_SOA_QUOTE_STEP_ONE } from "../../../soaQuote/utils/routes";
import { SOA_QUOTE_STEP_FOUR } from "../../../soaQuote/utils/constants";
import {
  ELECTRONIC_PAYMENT_STEP_THREE,
  ELECTRONIC_PAYMENT_END,
} from "../../utils/constants";

function SoaElectronicPaymentStepThree() {
  const soaQuoteStep = useSelector((state) => state.soaQuoteStep);
  const paymentStep = useSelector((state) => state.paymentStep);
  console.log("*** SOA STEP: ", soaQuoteStep);
  console.log("*** PAYMENT STEP: ", paymentStep);

  return (
    <>
      {soaQuoteStep === SOA_QUOTE_STEP_FOUR &&
      paymentStep >= ELECTRONIC_PAYMENT_STEP_THREE ? (
        <>
          <ElectronicPaymentHeader />
          <Container fixed>
            <div className="app">
              <ElectronicPaymentSteps paymentStep={ELECTRONIC_PAYMENT_END} />
              <SoaElectronicPaymentSuccessStepThree />
              <SoaElectronicPaymentDetailStepThree />
              <SoaBackButton />
            </div>
          </Container>
          <ElectronicPaymentFooter />
        </>
      ) : (
        <Redirect to={ROUTE_SOA_QUOTE_STEP_ONE} />
      )}
    </>
  );
}

export default SoaElectronicPaymentStepThree;
