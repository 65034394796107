import { useSelector } from "react-redux";
import { Breakpoint } from "react-socks";

import styles from "./SoaQuoteDetailStepTwo.module.css";

function SoaQuoteDetailStepTwo() {
  const vehicleTypeLabel = useSelector((state) => state.vehicleTypeLabel);
  const brandLabel = useSelector((state) => state.brandLabel);
  const year = useSelector((state) => state.year);

  return (
    <>
      <Breakpoint medium down>
        <section className={styles["container-responsive"]}>
          <div className={`${styles.text} ${styles.title}`}>
            Tipo de vehículo:
          </div>
          <div className={styles.text}>{vehicleTypeLabel}</div>
          <div className={`${styles.text} ${styles.title}`}>Año:</div>
          <div className={styles.text}>{year}</div>
          <div className={`${styles.text} ${styles.title}`}>Marca:</div>
          <div className={styles.text}>{brandLabel}</div>
        </section>
      </Breakpoint>

      <Breakpoint large up>
        <section className={styles.container}>
          <div className={`${styles.text} ${styles.title}`}>
            Tipo de vehículo:
          </div>
          <div className={`${styles.text} ${styles.title}`}>Año:</div>
          <div className={`${styles.text} ${styles.title}`}>Marca:</div>
          <div className={styles.text}>{vehicleTypeLabel}</div>
          <div className={styles.text}>{year}</div>
          <div className={styles.text}>{brandLabel}</div>
        </section>
      </Breakpoint>
    </>
  );
}

export default SoaQuoteDetailStepTwo;
