import styles from "./SoaFooter.module.css";

function SoaFooter() {
  return (
    <footer className={styles["app-footer"]}>
      <span>CONTACTO</span>
      <span className={styles.line} />
      <div className={styles["vertical-space"]}></div>
    </footer>
  );
}

export default SoaFooter;
