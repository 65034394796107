import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { Alert, Backdrop, AlertTitle, Container } from "@mui/material";
import { Breakpoint } from "react-socks";

import styles from "./SoaElectronicPaymentFormStepOne.module.css";
import {
  API_PAYMENT_METHODS_URL,
  API_BANKS_URL,
  API_CREDIT_CARDS_URL,
} from "../../utils/urls";
import { validateToken } from "../../../login/validateToken";
import { ROUTE_ELECTRONIC_PAYMENT_STEP_TWO } from "../../utils/routes";
import {
  issueInvoice,
  soaElectronicPaymentStepOneSuccess,
} from "../../../../redux/actions";
import Loading from "../../../Loading";
import SoaElectronicPaymentFileUploadStepOne from "./SoaElectronicPaymentFileUploadStepOne";

function SoaElectronicPaymentFormStepOne() {
  // Avoids showing errors from previous invocations (if you move back and return).
  const [submittedForm, setSubmittedForm] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [banks, setBanks] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [filesUploadError, setfilesUploadError] = useState(false);

  const issueInvoiceIsLoading = useSelector(
    (state) => state.issueInvoiceIsLoading
  );
  const issueInvoiceIsError = useSelector((state) => state.issueInvoiceIsError);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const history = useHistory();

  const aRef = useRef(null);

  useEffect(() => {
    const getPaymentMethods = async () => {
      const response = await axios({
        headers: { Authorization: `Bearer ${validateToken().token}` },
        method: "get",
        url: API_PAYMENT_METHODS_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setPaymentMethods(response.data);
    };

    const getCreditCards = async () => {
      const response = await axios({
        headers: { Authorization: `Bearer ${validateToken().token}` },
        method: "get",
        url: API_CREDIT_CARDS_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setCreditCards(response.data);
    };

    const getBanks = async () => {
      const response = await axios({
        headers: { Authorization: `Bearer ${validateToken().token}` },
        method: "get",
        url: API_BANKS_URL,
      });
      console.log("*** RESPONSE: ", response.data);
      setBanks(response.data);
    };

    getPaymentMethods();
    getBanks();
    getCreditCards();
  }, []);

  // Callback version of watch. Must unsubscribe when done.
  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log("*** SUBSCRIPTION: ", value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (submittedForm && !issueInvoiceIsLoading && !issueInvoiceIsError) {
      dispatch(soaElectronicPaymentStepOneSuccess());
      history.push(ROUTE_ELECTRONIC_PAYMENT_STEP_TWO);
    }
  }, [
    dispatch,
    history,
    submittedForm,
    issueInvoiceIsLoading,
    issueInvoiceIsError,
  ]);

  const onSubmit = (data) => {
    if (filesUploaded.length >= MAX_FILES) {
      const { label: paymentMethodLabel, paymentGateway } = paymentMethods.find(
        (paymentMethod) => paymentMethod.item === data.paymentMethodRadio
      );

      const bank = data.bank ? data.bank : "";
      const bankLabel = bank
        ? banks.find((bank) => bank.item === data.bank).label
        : "";

      const creditCard = data.creditCard ? data.creditCard : "";
      const creditCardLabel = creditCard
        ? creditCards.find((creditCard) => creditCard.item === data.creditCard)
            .label
        : "";

      const newData = {
        ...data,
        paymentMethodLabel,
        paymentGateway,
        bank,
        bankLabel,
        creditCard,
        creditCardLabel,
        filename1: filesUploaded[0],
        filename2: filesUploaded[1],
        filename3: filesUploaded[2],
        shortFilename1: shortFilename(filesUploaded[0]),
        shortFilename2: shortFilename(filesUploaded[1]),
        shortFilename3: shortFilename(filesUploaded[2]),
      };
      dispatch(issueInvoice(newData)); // Add new data to the store
      setfilesUploadError(false);
      setSubmittedForm(true);
    } else {
      setfilesUploadError(true);
      aRef.current.click();
    }
  };

  const watchPaymentMethodRadio = watch("paymentMethodRadio", "");

  const paymentMethodsRadios = paymentMethods.map((paymentMethod) => (
    <label key={paymentMethod.id} className={styles["label-radio"]}>
      <input
        autoFocus
        {...register("paymentMethodRadio", { required: true })}
        type="radio"
        value={paymentMethod.item}
        className={styles.radio}
      />
      {paymentMethod.label}
    </label>
  ));

  const banksOptions = banks.map((bank) => (
    <option key={bank.id} value={bank.item}>
      {bank.label}
    </option>
  ));

  const creditCardsOptions = creditCards.map((creditCard) => (
    <option key={creditCard.id} value={creditCard.item}>
      {creditCard.label}
    </option>
  ));

  const handleClose = () => {
    console.log("*** BACKDROP CLICK");
  };

  const MAX_FILES = 3;

  const shortFilename = (filename) => {
    const MAX_LENGTH = 10;
    const [name, ext] = filename.split(".");
    return name.length > MAX_LENGTH
      ? `${name.substr(0, MAX_LENGTH)}...${ext}`
      : filename;
  };

  const payment = (
    <>
      {errors.paymentMethodRadio?.type === "required" && (
        <Alert
          severity="error"
          sx={{ borderRadius: "20px", margin: "20px 0px" }}
        >
          Debe seleccionar un medio de pago
        </Alert>
      )}
      {watchPaymentMethodRadio === paymentMethods[0]?.item && (
        <>
          <label className={styles.label} htmlFor="bank">
            Indique el banco de su preferencia:
            <span className={styles.aster}>*</span>
          </label>
          <select
            className={styles.input}
            {...register("bank", { required: true })}
          >
            <option value="">Seleccione...</option>
            {banksOptions}
          </select>
          <small className={styles.note}>
            {`Para poder efectuar un pago en el sitio de testing de Sistarbanc debe elegir el banco Demo`}
          </small>
          {errors.bank?.type === "required" && (
            <Alert
              severity="error"
              sx={{ borderRadius: "20px", margin: "10px 0px" }}
            >
              Debe seleccionar un banco
            </Alert>
          )}
        </>
      )}
      {watchPaymentMethodRadio === paymentMethods[1]?.item && (
        <>
          <label className={styles.label} htmlFor="creditCard">
            Indique la tarjeta de crédito de su preferencia:
            <span className={styles.aster}>*</span>
          </label>
          <select
            className={styles.input}
            {...register("creditCard", { required: true })}
          >
            <option value="">Seleccione...</option>
            {creditCardsOptions}
          </select>
          {errors.creditCard?.type === "required" && (
            <Alert
              severity="error"
              sx={{ borderRadius: "20px", margin: "10px 0px" }}
            >
              Debe seleccionar una tarjeta de crédito
            </Alert>
          )}
        </>
      )}
    </>
  );

  return (
    <Container fixed>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <a style={{ display: "none" }} href="#fileUploadError" ref={aRef}>
          Link hidden
        </a>
        <div id="fileUploadError">
          {filesUploaded.length < MAX_FILES && (
            <SoaElectronicPaymentFileUploadStepOne
              maxFiles={MAX_FILES}
              setFilesUploaded={setFilesUploaded}
            />
          )}
        </div>

        {filesUploadError && filesUploaded.length < MAX_FILES && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            Faltan imágenes por adjuntar
          </Alert>
        )}

        {filesUploaded.length > 0 && (
          <section className={styles.container}>
            <div className={`${styles.text} ${styles.blue}`}>
              <span>Imágenes recibidas:</span>
            </div>
            <ul className={styles.list}>
              {filesUploaded.map((fileUploaded, index) => {
                return (
                  <li key={index} className={styles.text}>
                    {shortFilename(fileUploaded)}
                  </li>
                );
              })}
            </ul>
          </section>
        )}

        <div className={styles["title-container"]}>
          <span className={styles.title}>
            Seleccione el medio de pago a utilizar
          </span>
        </div>

        <Breakpoint medium down>
          <div className={styles["radio-container-responsive"]}>
            {paymentMethodsRadios}
          </div>
          <div className={styles["payment-method-container"]}>
            <div
              className={styles["payment-method-inner-container-responsive"]}
            >
              {payment}
            </div>
          </div>
        </Breakpoint>

        <Breakpoint large up>
          <div className={styles["radio-container"]}>
            {paymentMethodsRadios}
          </div>
          <div className={styles["payment-method-container"]}>
            <div className={styles["payment-method-inner-container"]}>
              {payment}
            </div>
          </div>
        </Breakpoint>

        {submittedForm && !issueInvoiceIsLoading && issueInvoiceIsError && (
          <Alert
            severity="error"
            sx={{ borderRadius: "20px", margin: "10px 0px" }}
          >
            <AlertTitle>
              <strong>Error inesperado al emitir o facturar la póliza</strong>
            </AlertTitle>
            Este error fue <strong>generado aleatoriamente</strong> por los
            microservicios del API RESTful que esta aplicación consume.
            <p />
            Presione continuar para reintentar la operación.
          </Alert>
        )}

        <input className={styles.mainButton} type="submit" value="Continuar" />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={submittedForm && issueInvoiceIsLoading}
          onClick={handleClose}
        >
          <Loading size="big" />
        </Backdrop>
      </form>
    </Container>
  );
}

export default SoaElectronicPaymentFormStepOne;
